<template>
  <div class="eheader">
    <BaseForm label-width="120px" :cols="formField" :form="query" @change="changeForm">
      <template #messageTypeSlot="{ scoped: { prop } }">
        <el-select v-model="query[prop]" clearable @change="changeForm">
          <el-option
            v-for="{ id, itemValue, itemName } in messageType"
            :key="id"
            :value="itemValue"
            :label="itemName"
          ></el-option>
        </el-select>
      </template>
      <template #isEnabledTypeSlot="{ scoped: { prop } }">
        <el-select v-model="query[prop]" clearable @change="changeForm">
          <el-option :value="1" label="已启用"></el-option>
          <el-option :value="0" label="未启用"></el-option>
          <el-option value="" label="全部"></el-option>
        </el-select>
      </template>
    </BaseForm>
  </div>
</template>

<script>
import { commonEheaderMixin } from '@/mixins'
import { formField } from './formFields'
import { getDicList, getDetailList } from '@/api/dicApi'
export default {
  mixins: [commonEheaderMixin],
  components: {},
  props: {},
  data() {
    return {
      formField,
      messageType: []
    }
  },
  computed: {},
  methods: {
    changeForm() {
      this.toQuery()
    },
    async getMessageType() {
      try {
        const { detail: list } = await getDicList({ page: { pageSize: 0, pageIndex: 1 } })
        const messageType = list.filter(({ dictType }) => {
          return dictType === 'messageType'
        })
        const { detail: detailList } = await getDetailList({
          dictId: messageType[0].id,
          page: { pageIndex: 1, pageSize: 0 }
        })
        this.messageType = detailList
      } catch {}
    }
  },
  created() {
    this.getMessageType()
  }
}
</script>

<style scoped lang="scss"></style>
